<template>
	<v-layout column justify-center>
		<v-flex v-for="provider in oauthProviders" :key="provider.id" shrink>
			<SignInSSOButtonQuickBooks v-if="provider.name === 'QuickBooks'" :provider="provider" />
			<v-layout v-else row justify-center mb-3>
				<v-flex>
					<v-tooltip>
						<template v-slot:activator="{ on }">
							<v-btn block :color="provider.primary_color" dark :href="provider.link" round v-on="on">
								<v-spacer v-if="provider.signin_logo" />
								<v-avatar v-if="provider.signin_logo" class="mr-2" size="35" tile>
									<v-img
										:alt="$t('logo')"
										class="pointer"
										contain
										height="100%"
										max-height="100%"
										shrink
										:src="provider.signin_logo"
										width="100"
									/>
								</v-avatar>
								<v-spacer />
							</v-btn>
						</template>
						<span v-text="$t('sso.continue_with', { name: provider.name })" />
					</v-tooltip>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'SignInSSOButtons',
	components: {
		SignInSSOButtonQuickBooks: () => ({
			component: import('@/components/SignIn/SignInSSOButtonQuickBooks')
		})
	},
	props: {
		oauthProviders: {
			required: true,
			type: Array
		}
	}
}
</script>
